<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="创建日期">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-date-picker
                  v-model="tableFilters.createdAt"
                  type="daterange"
                  style="width: 100%"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="5"
                ><el-button type="text" @click="checkCurrentMonth"
                  >显示本月</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="签约时间">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-date-picker
                  v-model="tableFilters.signedAt"
                  type="daterange"
                  style="width: 100%"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="5"
                ><el-button type="text" @click="checkCurrentMonthSignedAt"
                  >显示本月</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="负责人(多选)">
            <BasicSelect
              multiple
              v-model="tableFilters.ownerId"
              :allOptions="_ownerOptions"
            />
          </el-form-item>
        </el-col>

        <template v-if="showMoreFilter">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="部门(多选)">
              <el-cascader
                style="width: 100%"
                :options="_departmnetTreeOptions"
                v-model="tableFilters.departmentIds"
                collapse-tags
                :props="{
                  multiple: true,
                  emitPath: false,
                  label: 'name',

                  value: 'id',
                  children: 'subs',
                }"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="省份/州">
              <BasicSelect
                v-model="tableFilters.province"
                :allOptions="provinceOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="城市">
              <BasicSelect
                v-model="tableFilters.city"
                :allOptions="cityOptions"
                keyName="cname"
                valueName="cname"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="汇总方式">
              <el-select
                v-model="tableType"
                placeholder="请选择"
                style="width: 100%"
                @change="onSearch"
              >
                <el-option
                  v-for="item in tableTypeOptions"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
// import clientForm from "@/views/client/mixins/clientForm";
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
import { GetTableFormatParams } from "@/utils/table";
import { lastDayOfMonth, startOfMonth } from "date-fns";
export default {
  components: {
    BasicSelect,
  },
  // mixins: [clientForm],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: [],
        signedAt:[],
        province: "",
        city: "",
        ownerId: [],
        departmentIds: [],
      },
      tableType: 1,
      propObj: {
        country: "address.country",
        province: "address.province",
        city: "address.city",
        region: "address.region",
        ownerId: "owner.id",
        departmentIds: "owner.department.id",
      },
      tableTypeOptions: [
        { id: 1, name: "按省份和城市汇总显示" },
        { id: 2, name: "按省份汇总显示" },
      ],
    };
  },
  computed: {
    // userOptions() {
    //   return this.$store.getters.userOptions;
    // },
    regionOptions() {
      return this.$store.getters.regionOptions;
    },
    cityOptions() {
      return this.$store.getters.cityOptions;
    },
    provinceOptions() {
      return this.$store.getters.provinceOptions;
    },
    countryOptions() {
      return this.$store.getters.countryOptions;
    },
    _ownerOptions() {
      return this.$store.getters.ownerOptions;
    },
    _departmnetTreeOptions() {
      return this.$store.getters.departmnetTreeOptions;
    },
  },
  created() {
    this.checkCurrentMonth();
    // this.initFormSelection();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "clientAreaStatistics") {
          if (Object.keys(this.$route.params).length > 0) {
            for (const key in this.$route.params) {
              if (Object.hasOwnProperty.call(this.tableFilters, key)) {
                this.tableFilters[key] = this.$route.params[key];
              }
            }
            this.showMoreFilter = true;
            this.onSearch();
          }
        }
      },
    },
  },
  methods: {
    checkCurrentMonth() {
      this.tableFilters.createdAt = [
        startOfMonth(new Date()),
        lastDayOfMonth(new Date()),
      ];
      this.onSearch();
    },
    checkCurrentMonthSignedAt() {
      this.tableFilters.signedAt = [
        startOfMonth(new Date()),
        lastDayOfMonth(new Date()),
      ];
      this.onSearch();
    },
    onSearch() {
      let result = GetTableFormatParams(this.propObj, this.tableFilters);
      this.$emit("change", result, this.tableType);
      this.$emit("confirm", result, this.tableType);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      let result = GetTableFormatParams(this.propObj, this.tableFilters);
      this.$store.dispatch("basicOption/initOwnerOption");
      this.$store.dispatch("basicOption/initDepartmentTreeOption");
      this.$emit("change", result, this.tableType);
      this.$emit("clear", result, this.tableType);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 15px;
  margin-bottom: 0;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
  ::v-deep {
    .el-form-item__content {
      overflow: hidden;
    }
  }
}
</style>